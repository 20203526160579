import { createRoot } from 'react-dom/client'
import React, {Suspense, useRef} from 'react'
import { Canvas, useFrame, useThree} from '@react-three/fiber'
import { OrbitControls, Stats  } from '@react-three/drei'
import { SDFText, SDFTextProvider } from 'thomas'
import {Quaternion, Euler, Color} from "three";


import './App.css';



const Text2DScene = () => {
    const side = 80

    const {  camera } = useThree();


    const fontPathRegular = {
        sdfPath: 'https://cdn.jsdelivr.net/gh/Propile/fonts@main/isocpeur.png',
        fontPath: 'https://cdn.jsdelivr.net/gh/Propile/fonts@main/ISOCPEUR-msdf.json',
    }

    const fontPathBold = fontPathRegular;

    return (
        <Suspense>
            <SDFTextProvider fontPathRegular={fontPathRegular} fontPathBold={fontPathBold}>
                <>
                    <group>
                        {new Array(side * side).fill(0).map((_, i) => (
                            <group key={`Text ${i}`} position={[(i % side) * 5.5, Math.floor(i / side), 0]}>
                                <SDFText bold={false} color={new Color(0, 0, 0)} text={`Textä \n ${i}`}
                                         instanceKey={`Text ${i}`}/>
                            </group>
                        ))}
                    </group>
                    {/*<group>*/}
                    {/*    {new Array(side * side).fill(0).map((_, i) => (*/}
                    {/*        <group key={`Text ${i}`} position={[(i % side) * 4, Math.floor(i / side * 8), 0]}>*/}
                    {/*            <SDFText bold={false} color={new Color(0, 0, 0)} text={`Textä \n ${i}`}*/}
                    {/*                     instanceKey={`Text ${i}`}/>*/}
                    {/*        </group>*/}
                    {/*    ))}*/}
                    {/*</group>*/}
                    <group>
                        {new Array(side * side).fill(0).map((_, i) => (
                            <group key={`Text ${i}`} position={[(i % side) * 2, Math.floor(i / side * 4), 0]}>
                                <SDFText bold={false} color={new Color(0, 0, 0)} text={`Textä \n ${i}`}
                                         instanceKey={`Text ${i}`}/>
                            </group>
                        ))}
                    </group>
                </>

            </SDFTextProvider>
        </Suspense>

    )
}

const Text3DScene = () => {
    const side = 100

    const {camera} = useThree();


    const fontPathRegular = {
        sdfPath: 'https://cdn.jsdelivr.net/gh/Propile/fonts@main/isocpeur.png',
        fontPath: 'https://cdn.jsdelivr.net/gh/Propile/fonts@main/ISOCPEUR-msdf.json',
    }

    const fontPathBold = fontPathRegular;

    return (
        <Suspense>
            <SDFTextProvider fontPathRegular={fontPathRegular} fontPathBold={fontPathBold}>
                <group >
                    {new Array(side * side).fill(0).map((_, i) => (
                        <group key={`Text ${i}`} position={[(i % side) * 2, Math.floor(i / side * 4), 0]}>
                            <SDFText bold={false} color={new Color(0, 0, 0)} text={`Textä \n ${i}`} instanceKey={`Text ${i}`} />
                        </group>
                    ))}
                </group>
            </SDFTextProvider>
        </Suspense>

    )
}

export const App = () => {
    return (
        <>
            {/* First Canvas with 2D text */}
            <Canvas>
                <Stats />
                <ambientLight intensity={Math.PI / 2} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
                <OrbitControls />
                <Text2DScene />
                {/*<Text3DScene />*/}
                {/*<Text3DScene />*/}
            </Canvas>

            {/* Second Canvas with 3D text*/}
            {/*<Canvas>*/}
            {/*    <Stats />*/}
            {/*    <ambientLight intensity={Math.PI / 2} />*/}
            {/*    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />*/}
            {/*    <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />*/}
            {/*    <OrbitControls />*/}
            {/*    <Text3DScene />*/}
            {/*</Canvas>*/}
        </>
    )
}


